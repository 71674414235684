import {TableBase} from "../../tables/table_base";

class UpcomingBilling extends TableBase {
  constructor () {
    super('upcoming_billing');
  }

  paintTable ($upcomingBillingTable, upcoming_billing_data) {
    $upcomingBillingTable.empty()
    const operatorTemplate = `<td><a href="${app.CACHE.URL_ADMIN}opr/~operator_id~">~operator_name~</a></td>`;
    const resellerTemplate = `<td><a href="${app.CACHE.URL_ADMIN}res/~reseller_id~">~reseller_name~</a></td>`;
    const dateTemplate = `<td>~tacho_base_cutoff~</td>`;

    for (const row of upcoming_billing_data) {
      let operatorColumn = operatorTemplate;
      let resellerColumn = resellerTemplate;
      let dateColumn = dateTemplate;

      for (const key in row) {
        const val = row[key];
        operatorColumn = operatorColumn.replaceAll(`~${key}~`, val);
        resellerColumn = resellerColumn.replaceAll(`~${key}~`, val);
        dateColumn = dateColumn.replaceAll(`~${key}~`, val);
      }

      const $tr = $('<tr>').append($(operatorColumn),$(resellerColumn),$(dateColumn));
      $upcomingBillingTable.append($tr);
    }
  }
}

$(() => {
  if(app && app.DASHBOARD_CORE) {
    new UpcomingBilling();
  } else {
    console.warn('DashboardCore not initalized.');
  }
});
